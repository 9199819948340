import { template as template_ee13bcf50cc94fc0b45fc4481fae8e6e } from "@ember/template-compiler";
const FKText = template_ee13bcf50cc94fc0b45fc4481fae8e6e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
