import { template as template_f0b8765f846e43009ed94f48b5550f10 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f0b8765f846e43009ed94f48b5550f10(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
